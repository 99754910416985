<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="800px">
        <v-card>
            <v-card-title>
                <span>Editar Proveedor #{{supplier.id}}</span>
            </v-card-title>
            <v-card-text>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >

                    <v-row>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.name"
                                :counter="200"
                                label="Nombre"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.dni"
                                :counter="200"
                                label="R.U.T"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.giro"
                                :counter="200"
                                label="Giro"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        md="4"
                        ></v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.razon_social"
                                :counter="200"
                                label="Razón social"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.address"
                                :counter="200"
                                label="Dirección"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <!--
                            <v-text-field
                                v-model="supplier.bank"
                                :counter="200"
                                label="Banco"
                                required
                            ></v-text-field>
                            -->
                            <v-combobox
                                v-model="supplier.bank"
                                label="Banco"
                                required
                                :items="listadoBancos"
                            ></v-combobox>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <!--
                            <v-text-field
                                v-model="supplier.bank_account_type"
                                :counter="200"
                                label="Tipo de cuenta"
                                required
                            ></v-text-field>
                            -->
                            <v-combobox
                                v-model="supplier.bank_account_type"
                                label="Tipo de cuenta"
                                required
                                :items="listadoTiposCuenta"
                            ></v-combobox>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.bank_account_number"
                                :counter="200"
                                label="N° de cuenta"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="12">
                        <h4>Datos de contacto</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.contact_billing_name"
                                :counter="200"
                                label="Nombre de contacto"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.contact_billing_email"
                                :counter="200"
                                :rules="emailRules"
                                label="Email de contacto"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.contact_billing_phone"
                                :counter="200"
                                label="Teléfono de contacto"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.contact_comercial_name"
                                :counter="200"
                                label="Nombre de contacto comercial"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.contact_comercial_email"
                                :counter="200"
                                :rules="emailRules"
                                label="Email de contacto comercial"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                v-model="supplier.contact_comercial_phone"
                                :counter="200"
                                label="Teléfono de contacto comercial"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            supplier_id: undefined,
            supplier: {
                name: '',
                id: undefined,
            },
            emailRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email inválido.'
                },
            ],
            listadoBancos: [
                'BANCO SANTANDER',
                'BANCO SANTANDER BANEFE',
                'CORPBANCA',
                'BCI-TBANC',
                'BANCO FALABELLA',
                'BANCO ITAU',
                'BANCO DE CHILE / EDWARDS CITI',
                'BANCOESTADO',
                'BANCO BICE',
                'BANCO SECURITY',
                'BANCO CONSORCIO',
                'BANCO RIPLEY',
                'BANCO INTERNACIONAL',
                'SCOTIABANK',
                'COOPEUCH',
            ],
            listadoTiposCuenta: [
                'CUENTA CORRIENTE',
                'CUENTA VISTA',
                'CUENTA AHORRO',
            ]
        }
    },
    created(){
        var vm = this;
        this.$parent.$on('openDialogEditarProveedor', (supplier_id) => {
            this.supplier.id = supplier_id;
            this.axios({
                url: 'admin/suppliers/'+supplier_id+'/edit',
                method: 'POST',
                data: {
                    store: this.$store.state.passport.user.default_store_id,
                }
            }).then( response => {
                vm.supplier = response.data;
                vm.showDialog = true;
            }).catch( error => {
                console.log(error);
            });
        });
    },
    methods: {
        sendForm(){
            console.log('se deberia enviar el formulario');
            var vm = this;
            this.axios({
                url: 'admin/suppliers/'+this.supplier.id,
                method: 'PUT',
                data: this.supplier
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        
    }
}
</script>