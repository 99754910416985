<template>
    <div>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Proveedores en sistema
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        <div class="mx-4">
                            <v-btn @click="abrirDialogEditarUsuario" color="primary" fab x-small dark v-show="selected.length == 1">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                        <div class="mx-4">
                            <v-btn @click="$emit('openDialogCrearProveedor')" color="success" fab x-small dark >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Búsqueda"
                        single-line
                        hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        :search="search"
                        :single-select="true"
                        show-select
                        v-model="selected"
                        no-results-text="No hay resultados disponibles"
                        no-data-text="No hay datos disponibles"
                        loading-text="Cargando datos..."
                        class="elevation-1 datatable"
                    >
                        <template v-slot:item.creacion="{ item }">
                            <td class="text-center">
                                {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                            </td>
                        </template>
                        <template v-slot:item.actualizacion="{ item }">
                            <td class="text-center">
                                {{ $moment(item.updated_at).format('MMMM Do YYYY, h:mm:ss a') }}
                            </td>
                        </template>
                        <!--
                        <template v-slot:item.acciones="{ item }">
                            <td>
                                <v-icon
                                    small
                                    class="mr-2"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    small
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </template>
                        -->
                    </v-data-table>
                </v-card>
            </div>
        </div>
        <dialog-editar-proveedor @success="fetchData"></dialog-editar-proveedor>
        <dialog-crear-proveedor @success="fetchData"></dialog-crear-proveedor>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogEditarProveedor from "@/views/oms/Compras/DialogEditarProveedor.vue";
import DialogCrearProveedor from '@/views/oms/Compras/DialogCrearProveedor.vue';

export default {
    components: {
        DialogEditarProveedor,
        DialogCrearProveedor
    },
    data () {
            return {
                search: '',
                selected: [],
                headers: [
                    {
                    text: 'ID',
                    align: 'left',
                    sortable: true,
                    value: 'id',
                    },
                    { text: 'Nombre', value: 'name' },
                    { text: 'R.U.T', value: 'dni' },
                    { text: 'Razón social', value: 'razon_social' },
                    { text: 'Dirección', value: 'address' },
                    { text: 'Fecha creación', value: 'creacion' },
                    { text: 'Fecha actualización', value: 'actualizacion' },
                    { text: 'Acciones', value: 'acciones' },
                ],
                tableData: [],
            }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Proveedores", route: "proveedores" },
        ]);
        this.fetchData();
    },
    methods: {
        abrirDialogEditarUsuario(){
            let id = this.selected[0].id;
            this.$emit('openDialogEditarProveedor', id);
        },
        fetchData()
        {
            var vm = this;
            this.axios({
                url: 'admin/suppliers',
                method: 'POST',
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                vm.tableData = response.data;
            }).catch( error => {
                console.log(error);
            })
        }
    }
}
</script>